







































































































import { Component, Vue } from 'vue-property-decorator'
import {
  BAlert, BBadge,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback, BListGroup, BListGroupItem,
  BRow,
  BSpinner, BTab, BTabs
} from 'bootstrap-vue'
import { terminalService } from '@/store/terminalService'
import { RouteNames } from '@/router'
import leftovers from '@/store/leftovers'
import connectionModule, { WebSocketStatus } from '@/store/connection'
import category from '@/store/category'
import logsModules from '@/store/logs'

const Connections = () => import('@/views/developerPanel/connections.vue')
const Footer = () => import('@/views/footer.vue')
const Logs = () => import('@/views/developerPanel/logs.vue')
const Settings = () => import('@/views/developerPanel/settings.vue')

@Component({
  components: {
    Settings,
    Logs,
    Footer,
    Connections,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    BFormInvalidFeedback,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BAlert,
    BListGroup,
    BListGroupItem,
    BTabs,
    BTab,
    BBadge
  },
  filters: {
    // eslint-disable-next-line no-use-before-define
    status: DeveloperPanel.statusToString
  }
})
export default class DeveloperPanel extends Vue {
  authToken = ''
  loading=0
  readonly RouteNames = RouteNames

  get validation():boolean|null {
    return !!this.authToken.length || null
  }

  async sendForm():Promise<void> {
    try {
      this.loading += 1
      const mac = await terminalService.getMAC()
      if (!mac && this.authToken) { return }
      await terminalService.checkIn({ authToken: this.authToken })
      leftovers.load()
      category.actions.startWatchingSubscriptionsAndQueries()
      await this.$router.push({ name: RouteNames.index })
    } finally {
      this.loading -= 1
    }
  }

  get connection() {
    return connectionModule.state
  }

  static statusToString(v:WebSocketStatus) {
    const status = {
      [WebSocketStatus.Error]: 'Ошибка',
      [WebSocketStatus.Connected]: 'Подключён',
      [WebSocketStatus.Connecting]: 'В процессе подключения',
      [WebSocketStatus.Disconnected]: 'Не подключён',
      [WebSocketStatus.Reconnected]: 'Соединение восстановлено',
      [WebSocketStatus.Reconnecting]: 'Соединение восстанавливается'
    }
    return status[v]
  }

  get titleConnection() {
    return connectionModule.getters
      .allServicesAreAvailable ? 'Сервисы доступны' : 'Проблема с соединением'
  }

  get allServicesAvailable() {
    return connectionModule.getters.allServicesAreAvailable
  }

  get numberOfErrors() {
    return logsModules.state.errors.length
  }
}
